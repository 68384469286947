.center {
  width:  80%;
  min-height: 126px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 4px 16px 0px #37354514;
  margin-bottom: 1.1rem;
  cursor: pointer;
  padding: 20px;
}

.center-header {
  display: flex;
  justify-content: space-between;
}
.center .center-title {
  font-size: 18px;
  font-weight: 700;
  color: #0f0b26;
  margin-bottom: 20px;
}
.center .center-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #918f9f;
}
