@tailwind base;
@tailwind components;
@tailwind utilities;
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
li,
a {
    font-family: "Mulish", sans-serif;
}

input:focus,
input:active,
textarea:focus,
textarea:active,
button:focus {
    outline: none;
}

body {
    background: #fcf9fa !important;
    font-family: "Mulish", sans-serif !important;
}

li {
    list-style: none;
}

.MuiTab-textColorInherit.Mui-selected p {
    color: #251a6b !important;
    opacity: 1 !important;
    font-weight: 600;
}

.ant-btn {
    background-color: #251a6b;
}

/*
===================================================
ULTILITY CLASSES
===================================================
*/


/*
===================================================
BACKGROUND
===================================================
*/

.bg-red-dark {
    background-color: #e6072c;
}

.bg-red-light {
    background-color: #f5e9eb;
}


/*
===================================================
TEXTES
===================================================
*/

.text-white {
    color: #fff;
}

.text-red-dark {
    color: #e6072c;
}

.text-red-dark {
    color: #f5e9eb;
}


/*
===================================================
BUTTONS
===================================================
*/

.btn-lg {
    background-color: #e6072c;
    color: #fff;
    border-radius: 4px;
    width: 100%;
}

.btn-round-small {
    background-color: #e6072c;
    color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}


/*
===================================================
LAYOUTS
===================================================
*/

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.flex-row {
    flex-direction: row;
}

.flex-col {
    flex-direction: column;
}

.w-4\/5 {
    width: 80%;
}

.mt-5 {
    margin-top: 2.5rem;
}

.mt-3 {
    margin-top: 1.2rem;
}

.mt-10 {
    margin-top: 5rem;
}

.mb-5 {
    margin-bottom: 2.5rem;
}

.mb-10 {
    margin-bottom: 5rem;
}

.pt-5 {
    padding-top: 2.5rem;
}

.ml-2 {
    margin-left: 1.2rem;
}

.rounded-full {
    border-radius: 50%;
}

.py-0\.5 {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
}

.px-0\.5 {
    padding-left: 0.1rem;
    padding-right: 0.1rem;
}

.px-1 {
    padding-left: 0.2rem;
    padding-right: 0.2rem;
}

.v-screen {
    width: 100vw;
}

.h-screen {
    height: 100vh;
}

.shadow {
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.rounded {
    border-radius: 8px;
}

.border {
    border: 1px solid #dddddd;
}

.border-red {
    border-color: #e6072c;
}

.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.text-center {
    text-align: center;
}

.font-bold {
    font-weight: bold;
}

.font-medium {
    font-weight: 500;
}

.makeStyles-appBarShift-8 {
    z-index: 1 !important;
}