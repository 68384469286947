.form-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 50px 70px rgba(11, 16, 51, 0.06);
  border-radius: 16px;
}

.auth-button {
  background-color: #e6072c;
  color: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 0.6rem 0.8rem;
  margin-top: 0.5rem;
}

.proceed-to-payment {
  background-color: #e6072c;
  color: #fff;
  border-radius: 4px;
  width: 70%;
  padding: 0.6rem 0.8rem;
  margin-top: 0.5rem;
}
.form {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}
