.plan-wrapper {
    margin: 3rem;
}

.plan-container {
    display: flex;
    justify-content: space-between;
}

.back-btn {
    background-color: #4b3bae;
    color: white;
    padding: 0.2em;
    border-radius: 50%;
    cursor: pointer;
}

.lists {
    width: 100%;
}

.form-container {
    width: 100%;
}

.list-item {
    display: flex;
    position: relative;
    padding-left: 1rem;
}

.list-title {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
    color: #c6c5c5;
    transition: all 0.5s ease;
}

.list-description {
    width: 80%;
    font-size: 16px;
    margin-top: 0.2rem;
    color: #c6c5c5;
    transition: all 0.5s ease;
}

.check-container {
    position: absolute;
    left: 0;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0rem;
}

.check-mark {
    border-radius: 50%;
    padding: 0.3rem;
    background-color: #fbf9fa;
    left: 0;
    color: #c6c5c5;
    transition: all 0.5s ease;
}

.content-wrapper {
    position: absolute;
    top: 0;
}

.list-content {
    padding: 4rem 0 4rem 2rem;
    transition: all 0.5s ease;
}

.border-left {
    border-left-width: 2px;
    border-left-style: solid;
    border-left-color: #c6c5c5;
    transition: all 0.5s ease;
}

.completed-border {
    border-left-width: 2px;
    border-left-style: solid;
    border-color: #48b553 !important;
    transition: all 0.5s ease;
}

.active .list-title {
    color: #e6072c;
    opacity: 1;
}

.border-active {
    border-color: #48b553;
}

.border-inactive {
    border-color: #c6c5c5;
}

.active .check-mark {
    color: #48b553;
    opacity: 1;
}

.completed-mark {
    color: #48b553;
    opacity: 1;
}

.completed-list-title {
    color: #e6072c;
    opacity: 1;
}

.active .list-description {
    color: #5a5454;
    opacity: 1;
}

.completed-list-description {
    color: #5a5454;
    opacity: 1;
}