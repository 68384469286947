.plan-wrapper {
  margin: 1rem;
  
}

.plan-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width:100%;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.health-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2px;
  width:100%;
}
.health-container .title {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #0f0b26;
  margin-bottom: 20px;
}
.health-container .subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #273444;
  margin-bottom: 20px;
}
.back-btn {
  background-color: #4b3bae;
  color: white;
  padding: 0.2em;
  border-radius: 50%;
  cursor: pointer;
}

.lists {
  width: 100%;
}
.header-top {
  font-size: 15px;
}
.disabled-field {
  color: #989898;
  width: 475px;
  height: 58px;
  background: #e8e8e8;
  padding: 5px 15px 30px 15px;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
}
.title-container {
  display: block;
  align-items: center;
  margin-bottom: 1rem;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}
.sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #918f9f;
}
.form-container {
  width: 50%;
  align-items: flex-start;
}
.others-service {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #5a5454;
  margin-left: 6.2rem;
  margin-bottom: 2rem;
}
.form-step {
  text-align: left;
  margin-top: 1rem;
  margin-left: 6rem;
}
.divider {
  margin-top: 1rem;
  margin-right: 1rem;
  width: 230.5px;
  height: 4px;
  background: #dbd5fc;
}
.divider-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-item {
  display: flex;
  position: relative;
  padding-left: 1rem;
}

.list-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.list-description {
  width: 80%;
  font-size: 16px;
  margin-top: 0.2rem;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.check-container {
  position: absolute;
  left: 0;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0rem;
}

.check-mark {
  border-radius: 50%;
  padding: 0.3rem;
  background-color: #fbf9fa;
  left: 0;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.content-wrapper {
  position: absolute;
  top: 0;
}

.list-content {
  padding: 4rem 0 4rem 2rem;
  transition: all 0.5s ease;
}

.border-left {
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #c6c5c5;
  transition: all 0.5s ease;
}

.completed-border {
  border-left-width: 2px;
  border-left-style: solid;
  border-color: #48b553 !important;
  transition: all 0.5s ease;
}

.active .list-title {
  color: #e6072c;
  opacity: 1;
}

.border-active {
  border-color: #48b553;
}

.border-inactive {
  border-color: #c6c5c5;
}

.active .check-mark {
  color: #48b553;
  opacity: 1;
}

.completed-mark {
  color: #48b553;
  opacity: 1;
}

.completed-list-title {
  color: #e6072c;
  opacity: 1;
}

.active .list-description {
  color: #5a5454;
  opacity: 1;
}

.completed-list-description {
  color: #5a5454;
  opacity: 1;
}

.center {
  display: flex;
  height: 94px;
  width: 45%;
  border-radius: 4px;
  padding: 24px 12px 24px 16px;
  align-items: center;
  background: linear-gradient(0deg, #ffffff #ffffff), linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 16px 0px #37354514;
  margin-bottom: 1.8rem;
  cursor: pointer;
}
.center .combined {
  width: 45%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.center .image-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.center .center-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
}
.center .center-title {
  font-size: 18px;
  font-weight: 700;
  color: #0f0b26;
}
.center .center-subtitle {
  font-size: 14px;
  font-weight: 400;
  color: #918f9f;
}


@media screen and (max-width:1024px) {
  .form-container {
    width: 100%;
    align-items: flex-start;
  }
}