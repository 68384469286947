.plan-wrapper {
  padding: 3rem;
  width:100%;
  
}

.leftSideView {
  display: flex;
  width:60%;
  border-radius: 4px;
  padding: 20px;
  background: #dbd5fc;
  height: 371px;
  justify-content: space-between;

}

.rightSideView {
  display: flex;
  flex-direction: column;
  width:100%;
  margin-left: 4rem;
}


 
.selfservice {
  margin-bottom: 0.5rem;
  font-size: 18px;
  font-weight: 700;
  color: #0f0b26;
}
.rightSide {
  display: flex;
  min-height: 94px;

  border-radius: 4px;
  padding: 24px 12px 24px 16px;
  align-items: center;
  background: linear-gradient(0deg, #ffffff #ffffff), linear-gradient(0deg, #ffffff, #ffffff);
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 16px 0px #37354514;
  margin-bottom: 1.8rem;
  cursor: pointer;
}

.newrightSide {
  display: flex;
  min-height: 94px;
  width:90%;
  border-radius: 4px;
  padding: 24px 12px 24px 16px;
  align-items: center;
  background: white;
  border: 1px solid #f5f5f5;
  box-shadow: 0px 4px 16px 0px #37354514;
  margin-bottom: 1.8rem;
  cursor: pointer;
}
.rightSide .combined {
 width:100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.rightSide .image-view {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rightSide .rightSide-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 2rem;
}
.rightSide .rightSide-title {
  font-size: 18px;
  font-weight: 700;
  color: #0f0b26;
}
.rightSide .rightSide-subtitle {
  font-size: 12px;
  font-weight: 400;
  color: #918f9f;
}
.leftView {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}
.leftView .leftView-title {
  font-size: 18px;
  font-weight: bold;
}
.leftView .leftView-subtitle {
  font-size: 0.8rem;
}
.leftView .leftView-title-section {
  margin-left: 1rem;
}
.purple-container {
   margin-top: 60px;
}

.purple-container .purple-title {
  font-size: 14px;
  font-weight: 600;
  color: #918f9f;
  line-height: 16px;
  font-style: "SemiBold";
  margin-bottom: 1rem;
}
.purple-container .purple-date {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #0f0b26;
  margin-bottom: 2rem;
}
.purple-container .purple-subtitle {
  font-size: 14px;
  color: #0f0b26;
  font-weight: 600;
}

.leftSide {
  width: 60%;
}
.lists {
  width: 100%;
}

.form-container {
  width: 100%;
}

.list-item {
  display: flex;
  position: relative;
  padding-left: 1rem;
}

.list-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.list-description {
  width: 80%;
  font-size: 16px;
  margin-top: 0.2rem;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.check-container {
  position: absolute;
  left: 0;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0rem;
}

.check-mark {
  border-radius: 50%;
  padding: 0.3rem;
  background-color: #fbf9fa;
  left: 0;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.content-wrapper {
  position: absolute;
  top: 0;
}

.list-content {
  padding: 4rem 0 4rem 2rem;
  transition: all 0.5s ease;
}

.border-left {
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #c6c5c5;
  transition: all 0.5s ease;
}

.completed-border {
  border-left-width: 2px;
  border-left-style: solid;
  border-color: #48b553 !important;
  transition: all 0.5s ease;
}

.active .list-title {
  color: #e6072c;
  opacity: 1;
}

.border-active {
  border-color: #48b553;
}

.border-inactive {
  border-color: #c6c5c5;
}

.active .check-mark {
  color: #48b553;
  opacity: 1;
}

.completed-mark {
  color: #48b553;
  opacity: 1;
}

.completed-list-title {
  color: #e6072c;
  opacity: 1;
}

.active .list-description {
  color: #5a5454;
  opacity: 1;
}

.completed-list-description {
  color: #5a5454;
  opacity: 1;
}

@media screen and (max-width:1024px) {
  .rightSideView {
    display: flex;
    flex-direction: column;
    width:100%;
    margin-left:0;
  }
  
  .leftSideView {
    display: flex;
    width:100%;
    border-radius: 4px;
    background: #dbd5fc;
    height: 300px;
    justify-content: space-between;
    margin-bottom: 25px;

  
  }

  @media  screen and (max-width:765px) {
    .leftSideView {
      display:none;
      width:100%;
      border-radius: 4px;
      background: #dbd5fc;
      height: 300px;
      justify-content: space-between;
      margin-bottom: 25px;
  
    
    }
  }

  .purple-container {
    margin-top: 0px;
 }
 

  
.purple-container .purple-title {
  font-size: 14px;
  font-weight: 600;
  color: #918f9f;
  line-height: 16px;
  font-style: "SemiBold";
  margin-bottom: 1rem;
}
.purple-container .purple-date {
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #0f0b26;
}
}