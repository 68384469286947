.plan-wrapper {
  margin: 3rem;
}

.plan-container {
  display: flex;
  justify-content: space-between;
}



.back-btn {
  background-color: #4b3bae;
  color: white;
  padding: 0.2em;
  border-radius: 50%;
  cursor: pointer;
}

.lists {
  width: 100%;
}
.disabled-field {
  color: #989898;
  width: 100%;
  height: 58px;
  background: #e8e8e8;
  padding: 15px 10px 10px 10px;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid #e8e8e8;
}
.title-container {
  display: block;
  align-items: center;
  margin-bottom: 1rem;
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0.5rem;
}
.sub-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #918f9f;
}
.form-container {
  width: 50%;
  align-items: flex-start;
}
.others-service {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #5a5454;
  width: 100%;
  display:flex;
  justify-content: center;
}
.form-step {
  text-align: left;
  margin-top: 1rem;
  width:100%;
  padding: 0 20px;
}
.divider {
  width: 30%;
  height: 4px;
  background: #dbd5fc;
}

.divider:nth-child(2) {
  margin-left: 1rem;
  margin-right: 1rem;
  
}

.divider-container {
  margin-top: 20px;
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.list-item {
  display: flex;
  position: relative;
  padding-left: 1rem;
}
.back-button {
  background-color: #ffffff;
  color: #4f4f4f;
  border-radius: 8px;
  padding: 0.6rem;
  width: 25%;
  margin-right: 1rem;
  margin-top: 20px;
  border: 1px solid #989898;
}
.list-title {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  color: #c6c5c5;
  transition: all 0.5s ease;
}
.list-description {
  width: 80%;
  font-size: 16px;
  margin-top: 0.2rem;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.check-container {
  position: absolute;
  left: 0;
  height: 2rem;
  width: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0rem;
}

.check-mark {
  border-radius: 50%;
  padding: 0.3rem;
  background-color: #fbf9fa;
  left: 0;
  color: #c6c5c5;
  transition: all 0.5s ease;
}

.content-wrapper {
  position: absolute;
  top: 0;
}

.list-content {
  padding: 4rem 0 4rem 2rem;
  transition: all 0.5s ease;
}

.border-left {
  border-left-width: 2px;
  border-left-style: solid;
  border-left-color: #c6c5c5;
  transition: all 0.5s ease;
}

.completed-border {
  border-left-width: 2px;
  border-left-style: solid;
  border-color: #48b553 !important;
  transition: all 0.5s ease;
}

.active .list-title {
  color: #e6072c;
  opacity: 1;
}

.border-active {
  border-color: #48b553;
}

.border-inactive {
  border-color: #c6c5c5;
}

.active .check-mark {
  color: #48b553;
  opacity: 1;
}

.completed-mark {
  color: #48b553;
  opacity: 1;
}

.completed-list-title {
  color: #e6072c;
  opacity: 1;
}

.active .list-description {
  color: #5a5454;
  opacity: 1;
}

.completed-list-description {
  color: #5a5454;
  opacity: 1;
}


@media screen and (max-width:1024px) {

  .plan-container {
    display: flex;
    flex-direction: column;
  }
  .form-container {
    width: 100%;
    align-items: flex-start;
  }
}

@media screen and (max-width:500px) {
  .plan-wrapper {
    margin: 15px;
  }
}