.plan-reading-container {
    box-shadow: 0px -4px 12px rgba(55, 53, 69, 0.1);
    padding: 1rem 1.5rem 2rem 1.5rem;
    display: flex;
    justify-content: space-between;
    margin-bottom: 3rem;
}

.reading-price {
    font-size: 1.8em;
    white-space: nowrap;
    margin-top: 0.3rem;
}

.buy-button {
    display: block;
    background-color: #251a6b;
    color: #fff;
    white-space: nowrap;
    padding: 0.5rem 1rem;
    font-size: 1.2em;
    border-radius: 4px;
}