.login-screen {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
   
    background-color: #fcfaf9;
}
/* .login-screen {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fcfaf9;
} */

.right-container {
    min-height:120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.right-container {
    height: 100%;
    width: 100%;
}

.form-container {
    width: 80%;
    padding: 3rem 0 3rem 0;
}

@media screen and (max-width:500px) {
    .form-container {
        width: 100%;
        padding: 6rem 0 3rem 0;
        position:relative;
    }
    
}

.form-logo {
    width: 40px;
}

.form-title {
    color: #92929d;
    margin-top: 1rem;
    text-align: center;
}

.form {
    margin-top: 2rem;
}

.form-control label {
    color: #929292;
    font-size: 14px;
}

.form-control svg {
    font-size: 20px;
}

.form-control input::placeholder {
    display: block;
}

.form-control {
    margin-bottom: 1rem;
}

.form a {
    color: #e6072c;
    text-decoration: none;
}

.signup-button {
    background-color: #e6072c;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    padding: 0.6rem 0.8rem;
    margin-top: 0.5rem;
}