.left-container,
.right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-container {
    margin: 1rem 0;
}

.mobile-container {
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-top: 3rem;
}

.mobile-img {
    position: relative;
    right: -12%;
    width: 700px;
}

.play-img {
    width: 120px;
}

.left-container {
    background-color: #ec4d67;
    min-height: 100vh;
    height: 130vh;
    width: 80%;
}


@media screen and (max-width:969px) {
    .left-container {
        display: none;
    }
}

